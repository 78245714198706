import BaseApi, { ListResponse } from './BaseApi';

export const REQUEST_STATUS = {
  DECLINED: 0,
  APPROVED: 1,
  PENDING: 2,
};

export const REQUEST_TYPE = {
  DEPOSIT: 1,
  WITHDRAW: 2,
}

export interface LempiRequest {
  id: number;
  uuid: string;
  reference: string;
  bank: number;
  wallet: string;
  amount_locale: string;
  status: 0 | 1 | 2;
  type: number;
  created_datetime: string;
}

export default class RequestApi extends BaseApi {
  async list(page = 1) {
    const response = await this.get(`/api/request/?page=${page}`);
    const json = await response.json();
    return json as ListResponse<LempiRequest>;
  }

  async listPending(): Promise<LempiRequest[]> {
    const response = await this.get(`api/request/?status=${REQUEST_STATUS.PENDING}`);
    const json = await response.json();
    return json.results;
  }
}
