import store from '@/store';
import UserStore from '@/store/modules/user';
import { getModule } from 'vuex-module-decorators';

export interface ListResponse<ItemType> {
  count: number;
  next: string;
  previous: string;
  results: ItemType[];
}

export default class BaseApi {
  userStore = getModule(UserStore, store);

  get headers() {
    const headers = new Headers();
    const token = this.userStore.authToken;
    const csrfTokenRegex = document.cookie.match(/csrftoken=(\w+)(;|$|\s)/);
    headers.append('Authorization', `Token ${token}`);
    headers.append('Content-Type', 'application/json');
    if (csrfTokenRegex) {
      headers.append('X-CSRFToken', csrfTokenRegex[1]);
    }
    return headers;
  }

  get(url: string) {
    return fetch(url, { headers: this.headers });
  }

  post<T>(url: string, body: T) {
    return fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }
}
