




















import dateTimeFormat from '@/common/functions/dateTimeFormat';
import { BANK_LIST } from '@/common/models/Banks';
import { LempiRequest, REQUEST_STATUS } from '@/common/service/RequestApi';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'RequestList',
  filters: {
    bankLabel(bankId: number) {
      return BANK_LIST.find((bank) => bank.id === bankId)?.label;
    },
    dateTimeFormat,
    requestStatusIcon(status: number) {
      const entryStatus = Object.entries(REQUEST_STATUS).find(([, value]) => {
        return value === status;
      });
      const requestStatus = (entryStatus && entryStatus[0]) || '';
      const statusIcons: Record<string, string> = {
        PENDING: 'mdi-clipboard-clock',
        DECLINED: 'mdi-close-box',
        APPROVED: 'mdi-check-circle',
      };
      return statusIcons[requestStatus] || 'mdi-help-circle';
    },
    requestStatusLabel(status: number) {
      const entryStatus = Object.entries(REQUEST_STATUS).find(([, value]) => {
        return value === status;
      });
      const requestStatus = (entryStatus && entryStatus[0]) || '';
      const statusLabel: Record<string, string> = {
        PENDING: 'Procesando Solicitud',
        DECLINED: 'Solicitud Rechazada',
        APPROVED: 'Solicitud Aprovada',
      };
      return statusLabel[requestStatus] || 'Estado Desconocido';
    },
    requestStatusColor(status: number){
      const entryStatus = Object.entries(REQUEST_STATUS).find(([, value]) => {
        return value === status;
      });
      const requestStatus = (entryStatus && entryStatus[0]) || '';
      const statusColor: Record<string, string> = {
        PENDING: 'warn--text',
        DECLINED: 'error--text',
        APPROVED: 'success--text',
      };
      return statusColor[requestStatus] || '';
    },
  },
})
export default class RequestList extends Vue {
  @Prop({ required: true }) requests!: LempiRequest;
}
