















import moneyFormat from '@/common/functions/moneyFormat';
import { syncWallets } from '@/common/service/WalletApi';
import RequestApi, { LempiRequest } from '@/common/service/RequestApi';
import { Vue, Component } from 'vue-property-decorator';
import { BANK_LIST } from '@/common/models/Banks';
import { getModule } from 'vuex-module-decorators';
import UserStore from '@/store/modules/user';
import RequestList from '@/components/RequestList.vue';

@Component({
  name: 'AppHome',
  components: {
    RequestList,
  },
  filters: {
    moneyFormat,
  },
})
export default class AppHome extends Vue {
  userStore = getModule(UserStore, this.$store);

  requests: LempiRequest[] = [];

  requestApi = new RequestApi();

  async created() {
    syncWallets();
    const requests = await this.requestApi.listPending();
    this.requests = requests;
  }

  getBankLabel = (bankId: number) => {
    return BANK_LIST.find((bank) => bank.id === bankId)?.label;
  };

  getLocalDate = (date: string) => {
    return new Date(date).toLocaleString();
  };

  get balance() {
    return this.userStore.wallets.reduce((balance, wal) => balance + wal.balance, 0);
  }
}
